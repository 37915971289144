<template>
  <div>
    <b-row>
      <b-col cols="12" md="2"><b-form-select :options="eventStatusOptions" value-field="id" text-field="label" v-model="eventStatus" class="mb-2" /></b-col>
      <b-col><b-form-select :options="eventTypeOptions" value-field="id" text-field="label" v-model="eventType" class="mb-2" /></b-col>
      <b-col><b-form-select :options="eventSportOptions" value-field="id" text-field="label" v-model="eventSport" class="mb-2" /></b-col>
      <b-col cols="12" md="6"><b-form-select :options="eventLocationOptions" value-field="id" text-field="name" v-model="eventLocation" class="mb-2" /></b-col>
    </b-row>
    <hr />
    <b-card-group columns v-if="events.length > 0">
  	    <template v-if="events.length > 0">
          <b-card v-for="event in events" :border-variant="eventStatusVariant(event.status.label)" :key="event.id" :title="event.title" :sub-title="event.summary">
            <p><span :class="'text-' + eventStatusVariant(event.status.label)" v-html="$options.filters.eventStatusName(event.status.label)"></span>
              <small class="text-muted"> / {{ event.type.label }} / {{ event.sport.label }} [Ages {{ event.ageMin }} - {{ event.ageMax }}] / {{ $moment(event.startDate).format('YYYY-MM-DD') }}</small><br />
              <big>{{ event.location.name }}</big><br /><a 
            :href="googleMapUrl(event.location.name, event.location.address1, event.location.address2, event.location.postalCode)"
            target="_blank">{{event.location.address1}} {{event.location.address2}} {{event.location.postalCode}}</a></p>
            <b-row class="mb-2">
              <b-col>
                <b-row no-gutters>
                  <b-col cols="6" class="pr-1">
                    <b-button 
                      size="sm" 
                      variant="info" 
                      title="View participants" 
                      :to="{ name: 'eventRegistrationList', params: { eid: event.id } }" 
                      block
                    >{{ event.registrations.length }} <span class="sr-only">Players</span></b-button>
                  </b-col>
                  <b-col cols="6" class="pl-1">
                    <EventParticipantsExport :eid="event.id" eClass="btn btn-light btn-sm btn-block" />
                  </b-col>
                </b-row>
              </b-col>
              <b-col><b-button v-if="isDirector" size="sm" variant="success" title="Register a participant" :to="{ name: 'eventRegistrationForm', query: { eid: event.id } }" block>Register</b-button></b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col>
                <b-row no-gutters>
                  <b-col cols="6" class="pr-1"><b-button size="sm" :to="{ name: 'eventDetails', params: { eid: event.id } }" variant="primary" title="View event details" block>Details</b-button></b-col>
                  <b-col cols="6" class="pl-1"><b-btn size="sm" :variant="event.groups.length === 0 ? 'outline-success' : 'success'" :to="{ name: 'rosterCreate', params: { eid: event.id }}" block :disabled="!isDirector">Roster</b-btn></b-col>
                </b-row>
              </b-col>
              <b-col><b-button v-if="isDirector" size="sm" :to="{ name: 'eventEdit', params: { eid: event.id }}" variant="warning" title="Edit event details" block>Edit</b-button></b-col>
            </b-row>
            <b-row v-if="isDirector" class="mb-2">
              <b-col>
                <b-row no-gutters>
                  <b-col cols="6" class="pr-1"><b-button size="sm" :to="{ name: 'eventScheduleGenerator', params: { eid: event.id } }" variant="info" title="Generate a schedule" block>Gen</b-button></b-col>
                  <b-col cols="6" class="pl-1"><b-button size="sm" :to="{ name: 'eventSchedule', params: { eid: event.id } }" variant="info" title="Upload a schedule" block>Sched</b-button></b-col>
                </b-row>
              </b-col>
              <b-col><b-button size="sm" :to="{ name: 'eventStandings', params: { eid: event.id } }" variant="info" title="Upload standings" block>Standings</b-button></b-col>
            </b-row>
            <b-row v-if="isDirector">
              <b-col><b-button size="sm" :to="{ name: 'eventDuplicate', params: { eid: event.id }}" variant="warning" title="Duplicate this event" block :disabled="!isDirector">Duplicate</b-button></b-col>
              <b-col>
                <b-button v-if="event.status.id !== 5" size="sm" variant="danger" title="Archive this event" @click="onEventArchive(event.id)" block>Archive</b-button>
                <b-button v-else size="sm" variant="danger" title="Delete this event" @click="onEventDelete(event.id)" block>Delete</b-button>
              </b-col>
            </b-row>
          </b-card>
        </template>
    </b-card-group>
    <h3 v-else-if="eventsLoaded !== true" class="text-center">
      <b-spinner label="Loading events"></b-spinner> Loading events...
    </h3>
    <h3 v-else class="text-center">
      There are no events posted here yet.
    </h3>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EventParticipantsExport from '@/components/EventParticipantsExport'
export default {
  name: 'event-list',
  data () {
    return {
      eventsLoaded: false,
      eventLocation: null,
      eventType: null,
      eventSport: null,
      eventStatus: 'current'
    }
  },
  computed: {
    ...mapGetters([
      'business',
      'events',
      'eventLocations',
      'eventTypes',
      'sports',
      'eventStatuses'
    ]),
    eventLocationOptions () {
      let options = this.eventLocations
      options.push({id: null, name: 'All Locations'})
      return options
    },
    eventTypeOptions () {
      let options = this.eventTypes
      options.push({id: null, label: 'All Types'})
      return options
    },
    eventSportOptions () {
      let options = this.sports
      options.push({id: null, label: 'All Sports'})
      return options
    },
    eventStatusOptions () {
      let options = this.eventStatuses
      options.push({id: 'current', label: 'Pending & Open'})
      // options.push({id: 'non-archived', label: 'Non-Archived'})
      options.push({id: null, label: 'All Statuses'})
      return options
    }
  },
  methods: {
    ...mapActions([
      'getEvents',
      'resetEvents',
      'getSports',
      'getEventLocations',
      'getEventTypes',
      'getEventStatuses',
      'deleteEvent'
    ]),
    onEventArchive (id) {
      this.eventArchive(id).then(() => {
        this.showSuccess()
        this.getEventList()
      }, e => {
        this.showError({ message: e })
      })
    },
    async onEventDelete (id) {
      if (!confirm('Delete this event and its registrations?')) {
        return false
      }
      await this.deleteEvent(id)
      this.getEventList()
    },
    googleMapUrl (name, address1, address2, postalCode) {
      return 'https://www.google.com/maps/search/' + encodeURIComponent(name + ', ' + address1 + (address2 !== null ? ' ' + address2 : '') + ', ' + postalCode)
    },
    eventStatusVariant (status) {
      let statusClass = '',
      statusLower = status.split('-')[0].trim().toLowerCase()
      switch (statusLower)
      {
	      case 'pending': 
	        statusClass = 'warning'
	        break
	      case 'open': 
	        statusClass = 'info'
	        break
	      case 'active': 
	        statusClass = 'success'
	        break
	      case 'complete': 
	        statusClass = 'dark'
	        break
	      case 'archived': 
	        statusClass = 'muted'
	        break
      }
      return statusClass
    },
    getEventList () {
      if (!this.business?.id) {
        return
      }
      let obj = {
        business: this.business.id
      }
      if (this.eventLocation !== null) {
        obj.location = this.eventLocation
      }
      if (this.eventType !== null) {
        obj.type = this.eventType
      }
      if (this.eventSport !== null) {
        obj.sport = this.eventSport
      }
      if (this.eventStatus !== null) {
        obj.status = this.eventStatus
      }
      if (this.eventStatus === 'non-archived') {
        obj.status = [1,2]
      }
      if (this.eventStatus === 'current') {
        obj.status = [1,2]
      }
      return this.getEvents(obj)
    }
  },
  watch: {
    eventLocation () {
      this.getEventList()
    },
    eventType () {
      this.getEventList()
    },
    eventSport () {
      this.getEventList()
    },
    eventStatus () {
      this.getEventList()
    }
  },
  created () {
    if (!this.isDirector) {
      this.$router.push({ name: 'home' })
    }
  },
  async mounted () {
    this.getSports()
    this.getEventTypes().then(() => {
      if (typeof this.$route.query.type !== 'undefined') {
        this.eventType = this.$route.query.type
      }
    })
    this.getEventLocations({
      business: this.currentBusiness.id,
      active: true
    })
    this.getEventStatuses()
    await this.getEventList()
    this.eventsLoaded = true
  },
  destroyed () {
    this.resetEvents()
  },
  components: { EventParticipantsExport }
}
</script>